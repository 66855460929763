<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text class="d-flex">
        <v-row>
          <v-col md="3" cols="12">
            <v-avatar rounded size="200" class="me-6">
              <v-img :src="cropped"></v-img>
            </v-avatar>
            <v-btn
              color="primary"
              class="me-3 mt-5"
              @click="$refs.refInputEl.click()"
            >
              <v-icon class="d-sm-none">
                {{ icons.mdiCloudUploadOutline }}
              </v-icon>
              <span class="d-none d-sm-block">Upload Valid ID</span>
            </v-btn>
            <input
              ref="refInputEl"
              type="file"
              accept=".jpeg,.png"
              :hidden="true"
              v-on:click="$refs.refInputEl.value = ''"
              v-on:change="croppie"
            />
          </v-col>
          <v-col md="3" cols="12">
            <v-avatar rounded size="200" class="me-6">
              <v-img :src="cropped_2"></v-img>
            </v-avatar>
            <v-btn
              color="primary"
              class="me-3 mt-5"
              @click="$refs.refInputEl2.click()"
            >
              <v-icon class="d-sm-none">
                {{ icons.mdiCloudUploadOutline }}
              </v-icon>
              <span class="d-none d-sm-block">Upload Photo</span>
            </v-btn>
            <input
              ref="refInputEl2"
              type="file"
              accept=".jpeg,.png"
              :hidden="true"
              v-on:click="$refs.refInputEl2.value = ''"
              v-on:change="croppie_2"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col md="3" cols="12">
              <v-text-field readonlys v-model="branch" label="Branch" dense outlined readonly></v-text-field>
            </v-col>
            <v-col md="3" cols="12">
              <v-text-field
                v-model="account_no"
                label="Account No."
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-card flat>
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFORMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col md="4" cols="12"></v-col>
                <v-row>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-text-field
                      v-model="p_address"
                      label="Address"
                      dense
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="gender"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male', 'Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="contact_no"
                      label="Contact #"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_member" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      show: false,
      croppieImage_1: '',
      croppieImage_2: '',
      cropped: require('@/assets/images/avatars/1.png'),
      cropped_2: require('@/assets/images/avatars/2.png'),

      saving: false,
      alert: false,
      alert_message: '',

      account_no: '',
      last_name: '',
      first_name: '',
      middle_name: '',
      p_address: '',
      email_address: '',
      date_of_birth: '',
      place_of_birth: '',
      gender: '',
      mothers_maiden_name: '',
      contact_no: '',
      nationality: '',
      civil_status: '',
      nature_of_work: '',
      job_title: '',
      valid_id: '',
      id_no: '',
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    created() {
      this.initialize_data()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'branch',
        'branch_id',
        'year',
        'month_start',
        'month_end',
        'month_of',
        'user_id',
        'branch_address',
        'branch_contact_no',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('client_information', ['counter', 'save_client_information']),
      initialize_data() {
        const data = new FormData()
        data.append('year', this.year)
        this.counter(data)
          .then(response => {
            var characters = '0123456789';
            var charactersLength = characters.length;
            var result = '';
            for (var i = 1; i <= 5; i++) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            this.account_no = "GL-" + response.data + 1 + result
          })
          .catch(error => {
            this.saving = false
          })
      },
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      croppie(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage_1 = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      croppie_2(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_2 = this.croppieImage_2 = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      save_member() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id)
          data.append('maker_id', this.user_id)
          data.append('account_no', this.account_no)
          data.append('last_name', this.last_name.toUpperCase())
          data.append('first_name', this.first_name.toUpperCase())
          data.append('middle_name', this.middle_name.toUpperCase())
          data.append('date_of_birth', moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'))
          data.append('address', this.address)
          data.append('gender', this.gender)
          data.append('contact_no', this.contact_no)
          data.append('year', moment(this.month_of, 'MMMM YYYY').format('YYYY'))
          data.append('valid_id', this.croppieImage_1);
          data.append('picture', this.croppieImage_2);
          data.append('month_of', this.month_of)
          this.save_client_information(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
